import * as React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import './App.scss';
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Applications from "./pages/Applications";

const router = createBrowserRouter([
    {
      path: `${process.env.PUBLIC_URL}/`,
      element: <Layout/>,
    //   basename:'/',
      children: [
        {
            index: true,
            element: <Home/>
        },
        {
            path: `${process.env.PUBLIC_URL}/applications`,
            element: <Applications/>
        },
        {
            path: `${process.env.PUBLIC_URL}/about`,
            element: <About/>
        },
        {
            path: `${process.env.PUBLIC_URL}/contact`,
            element: <Contact/>
        },
      ]
    }
]);

function doScroll(e) {
    console.log(`x:${e.deltaX} y:${e.deltaY}`);
    e.preventDefault();
}

// window.addEventListener("wheel", doScroll, false);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
