import React, { useEffect, useState, useRef } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
// import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import applications from '../data/applications.json';

export default function ApplicationsHome() {
    
    const allApps = applications.data
    const totalApps = allApps.length.toString().padStart(2,'0')
    const [appID,setAppID] = useState([]);
    const [appImg,setAppImg] = useState([]);
    const [appTitle, setAppTitle] = useState([]);
    const [appDesc, setAppDesc] = useState([]);
    const [appCategory, setAppCategory] = useState([]);
    const [currentApp, setCurrentApp] = useState(0);
    const [isAction, setIsAction] = useState(false);
    const [blkShow, setBlkShow] = useState(['show']);
    
    const handleApp = (el) => {
        var action = el.target.parentNode.dataset.action
        if(action === 'next') {
            if(currentApp>=allApps.length-1) {
                setCurrentApp(0)
            } else {
                setCurrentApp(currentApp + 1)
            }
            setIsAction(true)
        } else if(action === 'prev') {
            if(currentApp<=0) {
                setCurrentApp(allApps.length-1)
            } else {
                setCurrentApp(currentApp - 1)
            }
            setIsAction(true)
        }
    }

    useEffect(()=>{
        if(allApps.length > 0) {
            const needAnim = document.querySelectorAll('.home--app [data-aos]')
            if(isAction) {
                setBlkShow('hide')
                setAppID(allApps[currentApp].id.padStart(2, "0"))
                setAppImg(allApps[currentApp].image)
                setAppTitle(allApps[currentApp].title)
                setAppDesc(allApps[currentApp].description)
                setAppCategory(allApps[currentApp].category)
                setTimeout(() => {
                    setBlkShow('show')
                }, 300);
            } else {

                setAppID(allApps[currentApp].id.padStart(2, "0"))
                setAppImg(allApps[currentApp].image)
                setAppTitle(allApps[currentApp].title)
                setAppDesc(allApps[currentApp].description)
                setAppCategory(allApps[currentApp].category)
            }
            // AOS.refreshHard()
        }
    },[allApps,currentApp,isAction])

    useEffect(() => {
        // AOS.init({
        //     duration: 1200,
        // });
        // return () => {
        //     AOS.refresh();
        // }; 
    }, []);
        
    return (
        <>
            {/* {allApps} */}
            <Row className='pt-3 pt-lg-5 home--app'>
                <Col className='col-12 col-lg-6' data-aos='fade-right' data-aos-delay="250">
                    <img className={'app--image ' + blkShow} src={process.env.PUBLIC_URL+ '/images/' + appImg + '.png'} alt={appImg}  />
                </Col>
                <Col className='col-12 col-lg-6 align-self-center' data-aos='fade-left' data-aos-delay="250">
                    <div className={'app--content ' + blkShow}>
                        <div className='app--category'>
                            <p className='skewBG inverse bg-primary text-dark d-inline-block'><span>{appCategory}</span></p>
                        </div>
                        <h4 className='app--title'>{appTitle}</h4>
                        <p className='app--desc'>{appDesc}</p>
                    </div>
                    <div className="appActs" data-aos='fade-in' data-aos-delay="250">
                        <span className='slideActs' onClick={handleApp.bind(this)}  data-action="prev"><i className="bi bi-caret-left-fill"></i></span>
                        <span className='mx-2'><span className='text-primary'>{appID}</span>/{totalApps}</span>
                        <span className='slideActs' onClick={handleApp.bind(this)} data-action="next"><i className="bi bi-caret-right-fill"></i></span>
                    </div>

                </Col>
            </Row>
            <Row className='justify-content-center mt-3' data-aos='fade-left' data-aos-delay="250">
                <Col className='text-center'>
                    <Link reloadDocument to={`${process.env.PUBLIC_URL}/Applications`} className='skewBG btn btn-primary miniText bg-transparent border border-secondary text-dark d-inline-block'><span>all possible applications</span></Link>
                </Col>
            </Row>
        </>
    )

    
}