import React, { useEffect, useState, useRef } from 'react';
import {
    Outlet
} from "react-router-dom";
import '../static/css/layout.scss';

import Header from '../components/Header';
import Footer from '../components/Footer';

export default function Layout() {
    var headerOffset = 80;

    useEffect(()=>{
        window.location.hash = window.decodeURIComponent(window.location.hash);
        const scrollToAnchor = () => {
            const hashParts = window.location.hash.split('#');
            const hash = hashParts.slice(-1)[0];
            if(hash) {
                setTimeout(()=>{
                    var elementPosition = document.querySelector(`#${hash}`).getBoundingClientRect().top;
                    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                
                    window.scrollTo({
                        top: offsetPosition,
                        behavior: "smooth"
                    });
                }, 300)
            }
            
        };
        scrollToAnchor();
        window.onhashchange = scrollToAnchor;
    },[])

    return(
        <>
            <Header/>

            <div className='main'>
        
                <Outlet/>
        
            </div>

            <Footer  data-aos="fade-up" />
        </>
    )
}