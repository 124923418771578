import React, { useEffect, useState, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import ApplicationsHome from '../components/AppsHome';

import imgAircraft from '../static/images/app-aircraft.png';
import imgRotorcraft from '../static/images/app-rotorcraft.png';
import imgSpacecraft from '../static/images/app-spacecraft.png';

import applications from '../data/applications.json'


function HomeCard(props) {
    let cardTitle = props.title
    let cardSubtitle = props.subtitle
    let cardCaption = props.caption
    return (
        <Card className='mx-auto mb-3' bg='dark' key={'dark'} text='white'>
            <Card.Body>
                <Card.Title>{cardTitle}</Card.Title>
                <Card.Subtitle className="mb-2 text-primary">{cardSubtitle}</Card.Subtitle>
                <Card.Text>
                    {cardCaption}
                </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default function Home() {

    const [show, setShow] = useState(false);

    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);

    useEffect(() => {
        AOS.init({
            duration: 800,
        });
        return () => {
            AOS.refresh();
        };
    }, []);

    return (
        <>
            <div className="container-fluid fullFold homeMain">
                <div data-aos='fade-down'>
                    <h1 className='text-primary'>Welcome to the World of Robust Control Systems</h1>
                </div>
                <div data-aos='fade-up' data-aos-delay="200" className='d-inline-block'>
                    <h2 className='skewBG d-inline-block'><span>Controlling Uncertain Systems with Certainty</span></h2>
                </div>
                <h6 data-aos='fade-up'>The presence of uncertainty affects the stability and performance of a control system. To mitigate the negative impact of uncertainty, control systems have to be designed to accommodate the uncertainty right at the design stage. That is where Robust Control Systems play a big role.</h6>
                <p><a href='#' onClick={modalShow}>Read more</a></p>
                <div className='scrollAlt animate__animated animate__bounce animate__delay-3s animate__repeat-2 animate__slow'>
                    <i className="bi bi-mouse"></i>
                </div>
            </div>

            <div id="HowWeDoIt" className="container-fluid fullFold homeSecond text-light">
                <h3 className='text-light mb-3 mb-xl-4' data-aos='fade-up' data-aos-delay="200">Transformation Allergic Approach</h3>
                <p className='col col-md-10 col-lg-8 col-xl-6 col-xxl-5 mb-0' data-aos='fade-up' data-aos-delay="200">Our firm Robust Engineering Systems, LLC developed a software named <span className='fw-bold'>RES CSD Tool-Box</span></p>
                <p className='mb-5' data-aos='fade-up' data-aos-delay="200">(Robust Engineering Systems Control Systems Design Toolbox)</p>

                <Container>
                    <Row className='mt-5 justify-content-md-center'>
                        <Col className='col-12 col-lg-4' data-aos='fade-up-right' data-aos-delay="200">
                            <HomeCard subtitle="______" title="Control System Design Techniques" caption="RES CSD Tool-Box  contains highly innovative, breakthrough type, new control systems design techniques not available in the current literature software Control Systems Design Tool-Box " />
                        </Col>
                        <Col className='col-12 col-lg-4' data-aos='fade-up' data-aos-delay="200">
                            <HomeCard subtitle="______" title="Novel & New Methods" caption="RES CSD Tool-Box contains novel, new methods of control systems design that can even stabilize many current literature control problems discarded by current literature software control systems design tool-box techniques as being difficult to stabilize and control." />
                        </Col>
                        <Col className='col-12 col-lg-4' data-aos='fade-up-left' data-aos-delay="200">
                            <HomeCard subtitle="______" title="Accommodating uncertainty" caption="RES CSD Tool-Box contains new control systems design methods that accommodate uncertainty in the form of real parameter variations, unmodeled dynamics, multiple equilibrium points, and errors in guidance commands and in the initial conditions and even few time varying perturbations. " />
                        </Col>
                    </Row>
                </Container>
            </div>

            <div id="currentLiterature" className="container-fluid halfFold currentLiterature">
                <div className='container'>
                    <Row className='align-items-md-center justify-content-center'>
                        <Col className='col-12 col-md-7'>
                            <h3 data-aos='fade-down' data-aos-delay="200">Current Literature</h3>
                            <p data-aos='fade-up' data-aos-delay="200">The current literature software based control systems designs are limited in their scope and are constrained by the obligation to connect time domain, state space systems with frequency domain, transfer function control theory. These designs aiming for negative real part eigenvalues as the stability criterion are highly non-robust having Zero Real Stability Margins.</p>
                        </Col>
                        <Col className='c0l-12 col-md-5'>
                            <div className='text-center'>
                                <Table className='border-primary' bordered variant='dark' size="sm"  data-aos='fade-left' data-aos-delay="200">
                                    <tbody>
                                        <tr>
                                            <td width={'45%'}>ss2tf</td>
                                            <td width={'10%'}><i className="bi bi-arrow-right"></i></td>
                                            <td width={'45%'}>Unique</td>
                                        </tr>
                                        <tr>
                                            <td>tf2ss</td>
                                            <td><i className="bi bi-arrow-right"></i></td>
                                            <td>Non-Unique</td>
                                        </tr>
                                        <tr>
                                            <td valign='middle'>TA Approach Convex Stability</td>
                                            <td valign='middle'><i className="bi bi-arrow-right"></i></td>
                                            <td>Pure State Space Design via Static Output Feedback Stabilization capability</td>
                                        </tr>
                                    </tbody>

                                </Table>
                            </div>
                        
                        </Col>
                    </Row>
                </div>
            </div>

            <div id="applications" className="container-fluid fullFold homeApplications">
                <div className='container'>
                    <h3 className='text-center mb-3' data-aos='fade-up' data-aos-delay="200">Applications</h3>
                    <ApplicationsHome />
                </div>
            </div>

            <Modal centered show={show} onHide={modalClose} dialogClassName="modal-xl">
                <Modal.Header closeButton>
                    <Modal.Title>Robust Engineering Systems</Modal.Title>
                </Modal.Header>
                <Modal.Body className=''>
                    <div>
                        <p>Our firm Robust Engineering Systems LLC, deviates from the current literature methods (which are labeled as Transformation Compliant methods) and offers a completely new, novel and innovative approach labeled as Transformation Allergic (TA) Approach to design highly robust engineering systems for multitude of discipline domains. .</p>
                        <p className='mb-0'>This TA Approach is IP protected under the recently awarded (in November 2023) US patent (number 11, 815, 862)</p>
                        <p>Please check below information on the patent details</p>
                    </div>
                    <hr/>
                    <div className='patentImg'>
                        <img src={process.env.PUBLIC_URL+ '/images/patent-00.png'} className="rounded img-fluid" alt="patent-cover"/>
                        <img src={process.env.PUBLIC_URL+ '/images/patent-01.png'} className="rounded img-fluid" alt="patent-cover"/>
                        <img src={process.env.PUBLIC_URL+ '/images/patent-02.png'} className="rounded img-fluid" alt="patent-cover"/>
                    </div>
                    <hr/>
                    <p>Under this patent protection, our firm RES has developed a new software package labeled as RES CSD Tool-Box (Transformation Allergic (TA) Approach Control Systems Design Tool-Box)  which offers an innovative, new and novel, pure State Space MIMO based approach to design highly robust control systems much different from the current literature eigenvalue based current literature software Control Systems Design Tool-Box designs being offered by the Mathworks company.  The RES developed RES CSD Tool-Box does not use eigenvalues as state variable convergence measures but instead uses Transformation Allergic Indices, which are always real scalars as the real state variable convergence measures. RES developed RES CSD Tool-Box offers much improved robustness to various uncertainties/perturbations such as real parameter variations, unmodeled dynamics, and accommodates time varying perturbations as well as multiple equilibrium points and errors in guidance commands and initial conditions together. RES CSD Tool-Box assumptions are much different from the current literature software CSD Tool-Box. Application areas of RES CSD Tool-Box include Aero and Space Systems, Electromechanical systems, Automotive and Robotics, Integrated Flight and Propulsion Control, Power/Energy Systems and Microgrid Stability and Control Systems with Communication Constraints and others.</p>

                    
                    <p className="text-center font-monospace mt-3" style={{fontSize: '13px'}}>Press <span className='fw-bold'>ESC</span> or click outside the box to close this message.</p>
                </Modal.Body>
            </Modal>
        </>
    )
}