import React, { useEffect, useState, useRef, useCallback } from 'react' 
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ReCAPTCHA from "react-google-recaptcha";

import AOS from 'aos';
import 'aos/dist/aos.css';
import SiteLoad from '../static/images/loading.svg'
import RESLogo from '../static/images/logo.svg';
import MrY from '../static/images/rama.k.yedavalli.png';

import iconCall from '../static/images/icons/call.svg';
import iconEmail from '../static/images/icons/email.svg';
import iconMap from '../static/images/icons/map.svg';
import iconTop from '../static/images/icons/toTop.svg';



export default function Footer() {

    const [show, setShow] = useState(['']);
    const [validated, setValidated] = useState(false);
    const captchaReference = useRef(null)
    const handleSubmit = (event) => {
        
        const siteLoad = document.getElementById('siteLoader')
        siteLoad.classList.add('show')
        const contactForm = event.currentTarget;
        
        setShow('show')
        if (contactForm.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setShow('')
        } else {
            event.preventDefault();
            const captcha = captchaReference.current.getValue();
            captchaReference.current.reset()
            setDisableSubmit(true)
            
            const contactFormD = new FormData()
            contactFormD.append('name',contactForm[0].value)
            contactFormD.append('email',contactForm[1].value)
            contactFormD.append('location',contactForm[2].value)
            contactFormD.append('profession',contactForm[3].value)
            contactFormD.append('queries',contactForm[4].value)
            // fetch("http://localhost/res-bk/get_site_data.php", {
            fetch("https://robustengsys.com/get_site_data.php", {
                method: "POST",
                body: contactFormD
            })
            .then(response => {
                return response.text()
            })
            .then(data => {
                if(data.includes('Sorry')) {
                } else {
                    contactForm.reset()
                }
                alert(data);
                var parser = new DOMParser();

                // Parse the text
                var doc = parser.parseFromString(data, "text/html");

                // You can now even select part of that html as you would in the regular DOM 
                // Example:
                // var docArticle = doc.querySelector('article').innerHTML;

                console.log(doc);
                setShow('')
            })
            .catch(error => {
                console.error("Error inserting data: " + error);
            });

        }

        setValidated(true);
    };

    const handleTop = () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

    useEffect(() => {
        AOS.init();
    }, []);

    const [disableSubmit,setDisableSubmit] = useState(true);

    return (
        <>
            <div className='subscribeNow py-4 py-xl-5'>
                <div className={'siteLoader ' + show} id='siteLoader'>
                    <div className='text-center'>
                        <img src={SiteLoad} />
                        <p className='my-0'>please wait. submitting form.</p>
                    </div>
                </div>
                <Form className='container subscribeForm' noValidate validated={validated} onSubmit={handleSubmit} data-aos='fade-down' data-aos-delay="200">
                    <Row className='mb-3'>
                        <Col>
                            <h2>Connect with us</h2>
                            <p className="mb-0">Have any questions? Write to us.</p>
                            <p><small><i class="bi bi-x-diamond-fill text-primary"></i> In case you need to request for a publication/paper, kindly use go to <a href='/about#publications'>this page</a>.</small></p>

                        </Col>
                    </Row>
                    
                    <Row className="">
                        <Form.Group as={Col} className='col-12 col-lg-6 col-xl mt-3' controlId="formGridName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control required type="text" placeholder="Enter Name" />
                        </Form.Group>

                        <Form.Group as={Col} className='col-12 col-lg-6 col-xl mt-3' controlId="formGridEmail" >
                            <Form.Label>Email</Form.Label>
                            <Form.Control required type="email" placeholder="Enter valid email" />
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} className='col-12 col-lg-6 col-xl mt-3' controlId="formGridCity" >
                            <Form.Label>City/Location</Form.Label>
                            <Form.Control type="text" placeholder="Enter the city you live or work from" />
                        </Form.Group>

                        <Form.Group as={Col} className='col-12 col-lg-6 col-xl mt-3' controlId="formGridProfession">
                            <Form.Label>Profession/Education</Form.Label>
                            <Form.Control type="Text" placeholder="Enter your profession or education if you are a student" />
                        </Form.Group>
                    </Row>
                    <Row className="mb-3">
                        <Form.Group as={Col} className='col-12 col-lg-6 col-xl mt-3' controlId="formGridQuestion">
                            <Form.Label>Queries</Form.Label>
                            <Form.Control type="text" placeholder='Type your question here' />
                        </Form.Group>
                    </Row>
                    
                    <Row className="justify-content-md-center mb-5">
                        <Col>
                            <ReCAPTCHA 
                                sitekey="6LfVwckpAAAAAL5GzbmJdETA_dE0TjZik_ysWD0p"
                                ref={captchaReference}
                                onChange={useCallback(() => setDisableSubmit(false))}
                            />
                        </Col>
                    </Row>
                    <Row className='mb-4'>
                        <Col className='text-center'>
                            <Button className='btn btn-info me-3' variant="primary" type="reset">Clear</Button>
                            <Button disabled={disableSubmit} className='btn btn-primary' variant="primary" type="submit">Submit</Button>
                        </Col>
                    </Row>
                </Form>
            </div>

            <footer className='footer container'>
                <Row className='mt-5 w-100'>
                    <Col className='col-lg-6' data-aos='fade-right' data-aos-delay="200">
                        <h2 className='text-primary'>Controlling<br/>Uncertain<br/>Systems with<br/>Certainty.</h2>
                    </Col>
                    <Col className='col-lg-6 profInfo'  data-aos='fade-left' data-aos-delay="200">
                        <div className='professor'><img src={MrY} alt='Rama.K.Yedavalli' /></div>
                        <h5 className='text-primary mb-0'>Rama K Yedavalli</h5>
                        <p className='fw-bold'>Founder & President</p>
                        <p>The Ohio State University</p>
                        <p>Academy Professor</p>
                    </Col>
                </Row>
                {/* <Row className='mt-3 mt-xl-4 w-100 align-items-end'>
                    <Col>
                        <img className='ftrLogo' src={RESLogo} alt='RES Logo'/>
                    </Col>
                    <Col className='text-end'>
                        <h2>Robust Engineering Systems LLC</h2>
                    </Col>
                </Row> */}
                <Row className='mt-3 w-100 py-3 py-xxl-4 border-top border-bottom' data-aos='fade-up' data-aos-delay="200">
                    <Col className='col-12 mb-3 mb-lg-0 col-lg d-flex align-items-center'>
                        <img className='ftrIcons' src={iconMap} alt='Map'/>
                        <div className='ftrActs'>
                            <p className='text-primary'>Office</p>
                            <p>6143 Wigeon Court | Dublin, Ohio 43017</p>
                        </div>
                    </Col>
                    <Col className='col-12 mb-3 mb-lg-0 col-lg d-flex align-items-center'>
                        <img className='ftrIcons' src={iconCall} alt='CaiconCall'/>
                        <div className='ftrActs'>
                            <p className='text-primary'>Call</p>
                            <p>614-284-7432</p>
                        </div>
                    </Col>
                    <Col className='col-12 mb-3 mb-lg-0 col-lg d-flex align-items-center'>
                        <img className='ftrIcons' src={iconEmail} alt='Email'/>
                        <div className='ftrActs'>
                            <p className='text-primary'>Email</p>
                            <p><a href='mailto:yedavallirk@gmail.com' className='text-secondary'>yedavallirk@gmail.com</a></p>
                        </div>
                    </Col>
                    <Col className='col-12 mb-3 mb-lg-0 col-lg-2 d-flex align-items-center text-end' onClick={handleTop}>
                        <img className='ftrIcons' src={iconTop} alt='Email'/>
                        <div className='ftrActs'>
                            <p>Back to Top</p>
                        </div>
                    </Col>
                </Row>
                <Row className='mt-3 mt-xl-4 w-100' >
                    <Col className='col-6 ftrLinks'>
                        <a href='#'>Terms & Conditions</a>
                        <a href='#'>Privacy Policy</a>
                    </Col>
                    <Col className='col-6 text-end'>
                        <p>Copyright &copy; {(new Date().getFullYear())}</p>
                    </Col>
                </Row>
            </footer>
        </>
    )
}