import React, { useEffect, useState, useRef } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'animate.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import icoMission from '../static/images/icons/mission.svg';
import icoValues from '../static/images/icons/values.svg';
import icoPurpose from '../static/images/icons/purpose.svg';
import icoImpact00 from '../static/images/icons/impact_00.svg';
import icoImpact01 from '../static/images/icons/impact_01.svg';
import icoImpact02 from '../static/images/icons/impact_02.svg';
import icoImpact03 from '../static/images/icons/impact_03.svg';
import icoImpact04 from '../static/images/icons/impact_04.svg';
import Container from 'react-bootstrap/esm/Container';


function ActionPub(props) {
    const selRef = useRef()
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const pubForm = event.currentTarget;

        if (pubForm.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            event.preventDefault();

            const pubFormD = new FormData()
            pubFormD.append('papid',pubForm[0].value)
            pubFormD.append('name',pubForm[1].value)
            pubFormD.append('email',pubForm[2].value)
            pubFormD.append('desc',pubForm[3].value)
            console.log(pubFormD)

            // fetch("http://localhost/res-bk/pet_sub_data.php", {
            fetch("https://robustengsys.com/pet_sub_data.php", {
                method: "POST",
                body: pubFormD
            })
            .then(response => {
                return response.text()
            })
            .then(data => {
                if(data.includes('Sorry')) {
                } else {
                    pubForm.reset()
                }
                alert(data);
                var parser = new DOMParser();

                // Parse the text
                var doc = parser.parseFromString(data, "text/html");    
                console.log(doc);
            })
            .catch(error => {
                console.error("Error inserting data: " + error);
            });
        }

        setValidated(true);
    };

    return (
        <div className='container'>
            <Row className='justify-content-center'>
                <Form className='col-12' noValidate validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="mb-3 d-none" controlId="formPUBid">
                        <Form.Label className='fw-bold'>Selected paper</Form.Label>
                        <Form.Select aria-label="pubSelection" defaultValue={props.tvalue} ref={selRef} required>
                            <option disabled value={0}>Open this select menu</option>
                            {props.tts}
                        </Form.Select>
                    </Form.Group>
                    <h5>Request for full paper</h5>
                    <Form.Group className="mt-4 mb-3" controlId="formPUBName">
                        <Form.Control className='text-center' type="text" placeholder="Enter your name" required/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formPUBEmail">
                        <Form.Control className='text-center' type="email" placeholder="Enter a valid email" required/>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formPUBDesc">
                        <Form.Control className='text-center' as="textarea" placeholder="Fill out the need for this paper" required/>
                    </Form.Group>
                    <Button variant="primary" type="submit" className='px-3 py-0'>
                         Submit
                    </Button>
                </Form>
            </Row>
        </div>
    )
}

function LinkPub(props) {
    return (
        <div className='container'>
            <Row className='justify-content-center'>
                <div className='col'>
                    <p>This publication is available online at the below link</p>
                    <Button href={props.plnk} target='_blank'>View Publisher Website</Button>
                </div>
            </Row>
        </div>
    )
}

export default function About() {

    const modelRef = useRef()
    const [show, setShow] = useState(false);
    const [showPub, setShowPub] = useState(false);

    const [pLink, setPLink] = useState(['']);
    const [pImg, setPImg] = useState(['']);
    const [pTitle, setPTitle] = useState(['']);
    const [pValue, setPValue] = useState(0);
    
    const [itemsPP, setItemsPP] = useState([]);

    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);

    const handleClose = () => setShowPub(false);
    const handleShow = (e) => {
        setShowPub(true);
        setPValue(e.target.dataset.pid)
        setPLink(e.target.dataset.link)
        setPImg(e.target.dataset.image)
        setPTitle(e.target.dataset.title)
    }

    const [allPubs,setAllPubs] =  useState([]);

    const handleModel = (e) => {
        e.preventDefault();
        e.stopPropagation();
        modalShow()
    }
    
    useEffect(() => {
        let itemsPPP = []; 
        for (let iP = 0; iP < allPubs.length; iP++) {
            const pp = allPubs[iP];
            if(pp.publink === "") {
                itemsPPP.push(<option key={iP} value={pp.id}>{pp.title}</option>);
            }
        }
        setItemsPP(itemsPPP)
    },[allPubs])

    useEffect(() => {
        // fetch('http://localhost/res-bk/getPublications.php')
        fetch('https://robustengsys.com/getPublications.php')
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                setAllPubs(data)
            });
    }, []);

    return (
        <>
            <div className="container-fluid fullFold homeMain">
                <div data-aos='fade-down' data-aos-delay="200" className='d-inline-block'>
                    <h2 className='skewBG'><span>About Us</span></h2>
                </div>
                <h5 data-aos='fade-up' data-aos-delay="200">At Robust Engineering Systems, our vision is to pioneer a world where certainty prevails, and uncertainties are transformed into opportunities.</h5>
                <p data-aos='fade-up' data-aos-delay="200">Founded in 2008 by Dr. Rama K. Yedavalli, currently Academy Professor at the Ohio State University. RES will undertake Research Projects in the fields of aerospace, automotive, robotic, energy, defense technologies and provide customized software modules.</p>
                <div className='scrollAlt animate__animated animate__bounce animate__delay-3s animate__repeat-2 animate__slow'>
                    <i className="bi bi-mouse"></i>
                </div>
            </div>

            <div id='vision' className='container-fluid fullFold py-4 py-xl-5 text-center'>
                <div className='container'>
                    <h3 data-aos='fade-up' data-aos-delay="200">Vision</h3>
                    <p className='text-block col col-md-10 col-lg-9 mb-5' data-aos='fade-up' data-aos-delay="200">Our vision at Robust Engineering Systems is to pioneer transformative solutions that shape the future of engineering. We envision a world where innovation meets reliability, where complex challenges yield elegant designs, and where sustainability and resilience are at the core of every system we create. Through bold exploration, unwavering integrity, and global impact, we aspire to be the driving force behind advancements that empower generations to come.</p>
                    <Row className='mt-5 vision-cards justify-content-center'>
                        <Col className='col-12 col-lg-4 col-xl-3' data-aos='fade-up-right' data-aos-delay="200">
                            <img src={icoMission} alt="about-mission" />
                            <h5>Mission</h5>
                            <p data-aos='fade-up' data-aos-delay="200">At Robust Engineering Systems, our mission is to design and build innovative, reliable, and resilient engineering solutions.</p>
                        </Col>
                        <Col className='col-12 col-lg-4 col-xl-3' data-aos='fade-up-left' data-aos-delay="200">
                            <img src={icoPurpose} alt="about-purpose" />
                            <h5>Purpose</h5>
                            <p data-aos='fade-up' data-aos-delay="200">Through collaboration, precision, and adaptable problem-solving, we aim to empower industries and communities by creating systems that withstand challenges and drive progress..</p>
                        </Col>
                        <Col className='col-12 col-lg-4 col-xl-3' data-aos='fade-up' data-aos-delay="200">
                            <img src={icoValues} alt="about-values" />
                            <h5>Values</h5>
                            <p data-aos='fade-up' data-aos-delay="200">We are committed to excellence in craftsmanship, continuous improvement, and client satisfaction.</p>
                        </Col>
                    </Row>
                </div>
            </div>

            <div id='impact' className='container-fluid halfFold py-4 py-xl-5 text-light'>
                <div className='container text-center'>
                    <h3 data-aos='fade-up' data-aos-delay="200">Impact</h3>
                    <p className='text-block col col-md-10 col-lg-9 mb-5' data-aos='fade-up' data-aos-delay="200">The impact of robust systems in engineering is significant, as they provide enhanced reliability, durability, and performance, ensuring that products and solutions can withstand and adapt to various challenges and uncertainties, ultimately leading to increased customer satisfaction and longevity of the engineered products.</p>

                    <div className='scroller col col-lg-10 mx-auto justify-content-evenly' data-aos='fade-up' data-aos-delay="200">
                        <div>
                            <img src={icoImpact00} alt=""/>
                            <h6>Control Systems</h6>
                        </div>
                        <div>
                            <img src={icoImpact01} alt=""/>
                            <h6>Radar & Comms</h6>
                        </div>
                        <div>
                            <img src={icoImpact02} alt=""/>
                            <h6>Control Systems</h6>
                        </div>
                        <div>
                            <img src={icoImpact03} alt=""/>
                            <h6>Radar & Comms</h6>
                        </div>
                        <div>
                            <img src={icoImpact04} alt=""/>
                            <h6>Control Systems</h6>
                        </div>
                    </div>
                </div>
            </div>

            <div id='publications' className='container-fluid fullFold py-4 py-xl-5'>
                <div className='container text-center'>
                    <h3 data-aos='fade-down' data-aos-delay="200">Patents & Publications</h3>
                    <p>Total Publication: {allPubs.length < 10 ? '0'+allPubs.length:allPubs.length}</p>
                    {/* <ShowPDF /> */}
                    <div className='pubPics mt-4'>
                        {allPubs.map((pubs,idx)=>{
                            return (
                                <div key={idx} data-aos='fade-in' data-aos-delay="200">
                                    <div className='pubLinks'>
                                        <p>{pubs.title}</p>
                                        <img src={'https://robustengsys.com/panel/' + pubs.file + '.jpg'} alt={pubs.file} data-aos='fade-in' data-aos-delay="200"/>
                                    </div>
                                    {/* <a role='button' href={pubs.publink} className='mt-3 mt-lg-4 mt-xxl-5 btn btn-primary' target='_blank' rel='noreferrer'>Link</a> */}
                                    <Button variant="primary" className='mt-4 px-3' onClick={handleShow} data-link={pubs.publink} data-title={pubs.title} data-image={'https://robustengsys.com/panel/' + pubs.file + '.jpg'} data-pid={pubs.id}>
                                        Request full paper
                                    </Button>
                                </div>
                            )
                        })}
                    </div>

                    <div className='text-center mt-5' data-aos='fade-up' data-aos-delay="200">
                        <p className='mb-0 fw-bold'>This site is covered by U.S. Patent No. 11,815,862</p>
                        <p>Please <a href='#' onClick={handleModel}>click here</a> for information on the patent details</p>
                    </div>
                    

                </div>
            </div>

            <Modal scrollable centered show={showPub} onHide={handleClose} dialogClassName="modal-xl" ref={modelRef} id="puBModal">
                <Modal.Header closeButton>
                    <Modal.Title>{pTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='text-center'>
                    <Container>
                        <Row className='justify-content-center'>
                            <Col md={8} lg={5}>
                                {pLink===""?<ActionPub tts={itemsPP} tvalue={pValue} />:<LinkPub plnk={pLink} />}
                            </Col>
                            <Col md={12} xl={10} className='my-5 border-top'>
                                <h5 className='mt-5'>Preview</h5>
                                <img src={pImg} className='w-75 border shadow' alt='Publication'/>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal centered show={show} onHide={modalClose} dialogClassName="modal-xl">
                <Modal.Body className=''>    
                    <Modal.Header closeButton>
                        <Modal.Title>Our Patent</Modal.Title>
                    </Modal.Header>

                    <div className='patentImg'>
                        <img src={process.env.PUBLIC_URL+ '/images/patent-00.png'} className="rounded img-fluid" alt="patent-cover"/>
                        <img src={process.env.PUBLIC_URL+ '/images/patent-01.png'} className="rounded img-fluid" alt="patent-cover"/>
                        <img src={process.env.PUBLIC_URL+ '/images/patent-02.png'} className="rounded img-fluid" alt="patent-cover"/>
                        <img src="https://robustengsys.com/thumb.jpg" className="rounded img-fluid" alt="patent-cover"/>
                    </div>
                    
                    <p className="text-center font-monospace mt-3" style={{fontSize: '13px'}}>Press <span className='fw-bold'>ESC</span> or click outside the box to close this message.</p>
                </Modal.Body>
            </Modal>
        </>

    )
}