import React from 'react';
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import evntDefault from '../static/images/event-place.jpg';

export default function Contact() {
    return (
        <div>
            <div className="container-fluid fullFold pageMain">
                <h2 className='skewBG'><span>Get In Touch</span></h2>
                <h1 className='text-primary'>Upcoming Events, FAQs and Newsletters</h1>
                
                <div className='scrollAlt'>
                    <i className="bi bi-mouse"></i>
                </div>
            </div>
            <div id='events' className="container-fluid fullFold">
                <div className='container'>
                    <h3 data-aos='fade-up' data-aos-delay="200">Upcoming Events</h3>
                    <p data-aos='fade-up' data-aos-delay="200">Get a chance to interact or meet Dr. Rama K Yedavalli in upcoming events</p>
                    <Row xs={1} md={3} className="g-4">
                        {Array.from({ length: 3 }).map((_, idx) => (
                            <Col key={idx}>
                                <Card data-aos='fade-up' data-aos-delay="300">
                                    <Card.Img variant="top" src={evntDefault} />
                                    <Card.Body>
                                        <Card.Title>Event Conference Name</Card.Title>
                                        <Card.Text className='mb-0 text-primary'>Date: 2nd March, 2024</Card.Text>
                                        <Card.Text className='mb-0'>Venue: Location</Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            </div>
            <div id='faqs' className="container-fluid fullFold">
                <div className='container'>
                    <h3 className='text-center' data-aos='fade-up' data-aos-delay="200">FAQs</h3>
                    <p className='text-block text-center col col-md-10 col-lg-9 mb-5' data-aos='fade-up' data-aos-delay="200">Frequently asked questions in the field of robust engineering</p>
                    <Accordion data-aos='fade-up' data-aos-delay="200">
                        <Accordion.Item eventKey="0" data-aos='fade-up' data-aos-delay="200">
                            <Accordion.Header>What is Robust Engineering</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1" data-aos='fade-up' data-aos-delay="200">
                            <Accordion.Header>What is RES CSD Toolbox?</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2" data-aos='fade-up' data-aos-delay="200">
                            <Accordion.Header> Duis aute irure dolor in
                                reprehenderit in voluptate velit esse?</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. cillum dolore eu fugiat nulla
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3" data-aos='fade-up' data-aos-delay="200">
                            <Accordion.Header>What are the subcription models for RES CSD Tool Box?</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                                culpa qui officia deserunt mollit anim id est laborum.
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4" data-aos='fade-up' data-aos-delay="200">
                            <Accordion.Header>Excepteur sint occaecat cupidatat non proident</Accordion.Header>
                            <Accordion.Body>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                                minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                pariatur. Sunt in
                                culpa qui officia deserunt mollit anim id est laborum.
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
            
            <div id='newsroom' className="container-fluid fullFold text-light">
                <div className='container'>
                    <h3 className='text-center' data-aos='fade-up' data-aos-delay="200">Newsroom</h3>
                    <p className='text-block text-center col col-md-10 col-lg-9 mb-5' data-aos='fade-up' data-aos-delay="200">Press relations and news.</p>
                </div>

            </div>
        </div>

    )
}